import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import slugify from '@sindresorhus/slugify';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Gallery, Item } from 'react-photoswipe-gallery';

// Components
import Layout from '../../components/layout/Layout';
import ShareBlock from '../../components/block/Share';
import TestimonialsSection from '../../components/section/Testimonials';
import RealisationCard from '../../components/card/Realisation';

// Realisation
const Realisation = ({ pageContext, data }) => {

  const { t } = useTranslation();

  const { languagePath } = pageContext;

  const pageImage = {
    url: data.realisation.frontmatter.image.cover ? data.realisation.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.realisation.frontmatter.image.cover ? data.realisation.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    type: data.realisation.frontmatter.image.cover ? data.realisation.frontmatter.image.cover.type : null,
    width: data.realisation.frontmatter.image.cover ? data.realisation.frontmatter.image.cover.childImageSharp.gatsbyImageData.width : null,
    height: data.realisation.frontmatter.image.cover ? data.realisation.frontmatter.image.cover.childImageSharp.gatsbyImageData.height : null,
    alt: data.realisation.frontmatter.image.alt ? data.realisation.frontmatter.image.alt : null
  };

  const pageSchemas = [
    {
      type: "breadcrumb",
      listItems: [
        {
          name: t('breadcrumb.realisations'),
          item: languagePath + "realisations/"
        },
        {
          name: data.realisation.frontmatter.title
        },
      ]
    }
  ];

  return (
    <Layout
      pageType="classic"
      pageTitle={data.realisation.frontmatter.meta.title}
      pageDescription={data.realisation.frontmatter.meta.description}
      pageKeywords={data.realisation.frontmatter.meta.keywords}
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-2">
        <nav className="border-bottom" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/realisations/`}>{t('breadcrumb.realisations')}</Link></li>
            <li className="breadcrumb-item active">{data.realisation.frontmatter.title}</li>
          </ol>
        </nav>
        <div className="content-space-1">
          <div className="row">
            <div className="col-lg-7 mb-7 mb-lg-0 order-lg-first">
              <MDXProvider
                components={{
                  h2: props => <h2 {...props} className="mt-5 mb-3">{props.children}</h2>,
                  h3: props => <h3 {...props} className="mt-5 mb-3">{props.children}</h3>,
                  h4: props => <h4 {...props} className="mt-5 mb-3">{props.children}</h4>,
                  h5: props => <h5 {...props} className="mt-5 mb-3">{props.children}</h5>,
                  a: props => <a {...props} className="text-highlight-warning" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                  Link: props => <Link {...props} className="text-highlight-warning">{props.children}</Link>,
                }}
              >
                <MDXRenderer
                  localImages={data.realisation.frontmatter.images}
                >
                  {data.realisation.body}
                </MDXRenderer>
              </MDXProvider>
            </div>
            <div className="col-lg-5 order-first">
              <div className="ps-lg-4">
                <div className="mb-6">
                  {data.realisation.frontmatter.title &&
                    <h1 className="h2">{data.realisation.frontmatter.title}</h1>
                  }
                  {data.realisation.frontmatter.description &&
                    <p>{data.realisation.frontmatter.description}</p>
                  }
                </div>
                <hr className="mt-3 mb-5" />
                {data.client &&
                  <div className="row">
                    <div className="col-sm mb-2 mb-sm-0">
                      <div className="d-sm-flex">
                        <div className="flex-shrink-0 mb-3 mb-sm-0">
                          <GatsbyImage
                            image={getImage(data.client.frontmatter.image.logo)}
                            className="avatar avatar-xl avatar-circle border"
                            alt={data.client.frontmatter.image.alt}
                          />
                        </div>
                        <div className="flex-grow-1 ms-sm-4">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="mb-0">
                              {data.client.frontmatter.name &&
                                <Link to={`/client/${data.client.frontmatter.path}/`} className="text-dark">{data.client.frontmatter.name}</Link>
                              }
                            </h3>
                          </div>
                          {data.client.frontmatter.country &&
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <ul className="list-inline text-center mb-0">
                                {data.client.frontmatter.country &&
                                  <li key="country" className="list-inline-item">
                                    {(() => {
                                      if (data.client.frontmatter.country === 'de') {
                                        return (
                                          <div className="btn btn-outline-primary btn-xs rounded">
                                            <StaticImage className="avatar avatar-xss avatar-rounded me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/de-de.png`} alt={t('countries.' + data.client.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.client.frontmatter.country + '.label')}
                                          </div>
                                        )
                                      }
                                      if (data.client.frontmatter.country === 'fr') {
                                        return (
                                          <div className="btn btn-outline-primary btn-xs rounded">
                                            <StaticImage className="avatar avatar-xss avatar-rounded me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/fr-fr.png`} alt={t('countries.' + data.client.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.client.frontmatter.country + '.label')}
                                          </div>
                                        )
                                      }
                                      if (data.client.frontmatter.country === 'gb') {
                                        return (
                                          <div className="btn btn-outline-primary btn-xs rounded">
                                            <StaticImage className="avatar avatar-xss avatar-rouned me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/en-gb.png`} alt={t('countries.' + data.client.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.client.frontmatter.country + '.label')}
                                          </div>
                                        )
                                      }
                                      if (data.client.frontmatter.country === 'ru') {
                                        return (
                                          <div className="btn btn-outline-primary btn-xs rounded">
                                            <StaticImage className="avatar avatar-xss avatar-rounded me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/ru-ru.png`} alt={t('countries.' + data.client.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.client.frontmatter.country + '.label')}
                                          </div>
                                        )
                                      }
                                    })()}
                                  </li>
                                }
                                {data.client.frontmatter.website_url &&
                                  <li key="website" className="list-inline-item">
                                    <a className="btn btn-outline-primary btn-xs rounded" target="_blank" rel="noopener noreferrer" href={data.client.frontmatter.website_url}>
                                      <i className="bi-globe"></i> {t('website')}
                                    </a>
                                  </li>
                                }
                              </ul>
                            </div>
                          }
                          {data.client.frontmatter.social_networks &&
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <ul className="list-inline text-center mb-0">
                                {data.client.frontmatter.social_networks.google &&
                                  <li key="linkedIn" className="list-inline-item">
                                    <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.client.frontmatter.social_networks.google}>
                                      <i className="bi-google"></i>
                                    </a>
                                  </li>
                                }
                                {data.client.frontmatter.social_networks.linkedIn &&
                                  <li key="linkedIn" className="list-inline-item">
                                    <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.client.frontmatter.social_networks.linkedIn}>
                                      <i className="bi-linkedin"></i>
                                    </a>
                                  </li>
                                }
                                {data.client.frontmatter.social_networks.facebook &&
                                  <li key="facebook" className="list-inline-item">
                                    <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.client.frontmatter.social_networks.facebook}>
                                      <i className="bi-facebook"></i>
                                    </a>
                                  </li>
                                }
                                {data.client.frontmatter.social_networks.instagram &&
                                  <li key="instagram" className="list-inline-item">
                                    <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.client.frontmatter.social_networks.instagram}>
                                      <i className="bi-instagram"></i>
                                    </a>
                                  </li>
                                }
                                {data.client.frontmatter.social_networks.twitter &&
                                  <li key="twitter" className="list-inline-item">
                                    <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.client.frontmatter.social_networks.twitter}>
                                      <i className="bi-twitter"></i>
                                    </a>
                                  </li>
                                }
                              </ul>
                            </div>
                          }
                          {data.client.body &&
                            <div>
                              <MDXRenderer>
                                {data.client.body}
                              </MDXRenderer>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <hr className="mt-3 mb-5" />
                {data.partners.nodes.length ?
                  <dl className="row">
                    <dt className="col-sm-4">{t('partners')}</dt>
                    <dd className="col-sm-8">
                      <ul className="list-unstyled text-muted">
                        {data.partners.nodes.map(partner => (
                          <li key={partner.id} className="d-block">
                            <Link to={`/partner/${partner.frontmatter.path}/`}>
                              {partner.frontmatter.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </dl>
                  : null}
                {data.services.group.length ?
                  <dl className="row">
                    <dt className="col-sm-4">{t('services')}</dt>
                    <dd className="col-sm-8">
                      <ul className="list-unstyled text-muted">
                        {data.services.group.map(service => (
                          <Link key={service.title} to={`/realisations/services/${slugify(service.title)}/`}>
                            {t('realisation.services.' + service.title + '.label')}
                          </Link>
                        ))}
                      </ul>
                    </dd>
                  </dl>
                  : null}
                {data.realisation.frontmatter.category ?
                  <dl className="row">
                    <dt className="col-sm-4">{t('categories')}</dt>
                    <dd className="col-sm-8">
                      <ul className="list-unstyled text-muted">
                        <Link to={`/realisations/categories/${slugify(data.realisation.frontmatter.category)}/`} className="btn btn-soft-secondary btn-xs mb-1 me-1">
                          {t('realisation.categories.' + data.realisation.frontmatter.category + '.label')}
                        </Link>
                      </ul>
                    </dd>
                  </dl>
                  : null}
                {data.tags.group.length ?
                  <dl className="row">
                    <dt className="col-sm-4">{t('tags')}</dt>
                    <dd className="col-sm-8">
                      <ul className="list-unstyled text-muted">
                        {data.tags.group.map(tag => (
                          <Link key={tag.title} to={`/realisations/tags/${slugify(tag.title)}/`} className="btn btn-soft-secondary btn-xs mb-1 me-1">
                            {t('realisation.tags.' + tag.title + '.label')}
                          </Link>
                        ))}
                      </ul>
                    </dd>
                  </dl>
                  : null}
                {data.technologies.group.length ?
                  <dl className="row">
                    <dt className="col-sm-4">{t('technologies')}</dt>
                    <dd className="col-sm-8">
                      <ul className="list-unstyled text-muted">
                        {data.technologies.group.map(technology => (
                          <Link key={technology.title} to={`/realisations/technologies/${slugify(technology.title)}/`} className="btn btn-soft-secondary btn-xs mb-1 me-1">
                            {t('realisation.technologies.' + technology.title + '.label')}
                          </Link>
                        ))}
                      </ul>
                    </dd>
                  </dl>
                  : null}
                {data.languages.group.length ?
                  <dl className="row">
                    <dt className="col-sm-4">{t('languages')}</dt>
                    <dd className="col-sm-8">
                      {data.languages.group.map((language, index) => (
                        <span key={index}>
                          {language.title === 'en-gb' &&
                            <Link key={language.title} to={`/realisations/languages/${slugify(language.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('realisation.languages.' + language.title + '.label')}>
                              <StaticImage className="avatar avatar-xss avatar-rouned me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/en-gb.png`} alt={t('realisation.languages.' + language.title + '.label')} width={20} height={20} />{t('realisation.languages.' + language.title + '.label')}
                            </Link>
                          }
                          {language.title === 'fr-fr' &&
                            <Link key={language.title} to={`/realisations/languages/${slugify(language.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('realisation.languages.' + language.title + '.label')}>
                              <StaticImage className="avatar avatar-xss avatar-rouned me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/fr-fr.png`} alt={t('realisation.languages.' + language.title + '.label')} width={20} height={20} />{t('realisation.languages.' + language.title + '.label')}
                            </Link>
                          }
                          {language.title === 'ru-ru' &&
                            <Link key={language.title} to={`/realisations/languages/${slugify(language.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('realisation.languages.' + language.title + '.label')}>
                              <StaticImage className="avatar avatar-xss avatar-rouned me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/ru-ru.png`} alt={t('realisation.languages.' + language.title + '.label')} width={20} height={20} />{t('realisation.languages.' + language.title + '.label')}
                            </Link>
                          }
                        </span>
                      ))}
                    </dd>
                  </dl>
                  : null}
                <hr className="mt-3 mb-5" />
                <dl className="row">
                  <dt className="col-sm-4">{t('share')}</dt>
                  <dd className="col-sm-8">
                    <ShareBlock
                      data={data.realisation}
                      title={data.realisation.frontmatter.title}
                      description={data.realisation.frontmatter.description}
                      blockStyle="3"
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        {data.realisation.frontmatter.galleries && data.realisation.frontmatter.galleries.length ?
          <div className="border-top content-space-1 mx-lg-auto">
            <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-3 mb-sm-5">
              <h2>{t('gallery')}</h2>
            </div>
            <div className="row mt-4">
              <Gallery
                shareButton={false}
              >
                {data.realisation.frontmatter.galleries.map((gallery, index) => (
                  <>
                    {gallery && gallery.childImageSharp &&
                      <div key={index} className="col-md-4 col-sm-6 col-12 mb-4">
                        <Item
                          original={gallery.childImageSharp.fluid.srcWebp}
                          thumbnail={gallery.childImageSharp.fluid.srcWebp}
                          width={gallery.childImageSharp.original.width}
                          height={gallery.childImageSharp.original.height}
                        >
                          {({ ref, open }) => (
                            <div ref={ref} onClick={open} onKeyDown={open}>
                              <GatsbyImage
                                ref={ref}
                                onClick={open}
                                image={getImage(gallery)}
                                imgClassName="img-fluid rounded-2 border"
                                alt={data.realisation.frontmatter.image.alt}
                              />
                            </div>
                          )}
                        </Item>
                      </div>
                    }
                  </>
                ))}
              </Gallery>
            </div>
          </div>
          : null}
        <div className="border-top content-space-1 mx-lg-auto">
          <p>
            <span>{t('followUsOn')} </span>
            {data.site.siteMetadata.socialNetworks.linkedIn ?
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.linkedIn} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
                <span>, </span>
              </span>
              : null}
            {data.site.siteMetadata.socialNetworks.facebook ?
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.facebook} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
                <span>, </span>
              </span>
              : null}
            {data.site.siteMetadata.socialNetworks.instagram ?
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>
                <span>, </span>
              </span>
              : null}
            {data.site.siteMetadata.socialNetworks.twitter ?
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.twitter} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
                <span>.</span>
              </span>
              : null}
          </p>
          <div className="row justify-content-sm-between align-items-sm-center mt-5">
            <div className="col-sm mb-2 mb-sm-0">
              <div className="d-flex align-items-center">
                <span className="text-cap mb-0 me-2">{t('share')}</span>
                <div className="d-flex gap-2">
                  <ShareBlock
                    data={data.realisation}
                    title={data.realisation.frontmatter.title}
                    description={data.realisation.frontmatter.description}
                    blockStyle="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {data.testimonials.nodes.length ?
          <div className="border-top content-space-1 mx-lg-auto">
            <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-3 mb-sm-5">
              <h2>{t('testimonials.title')}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="2" service="realisation" />
          </div>
          : null}
        {data.relatedRealisations.nodes.length ?
          <div className="border-top content-space-1 mx-lg-auto">
            <div className="mb-3 mb-sm-5">
              <h2>{t('otherRealisations', { name: data.client.frontmatter.name })}</h2>
            </div>
            <div className="row">
              {data.relatedRealisations.nodes.map(realisation => (
                <RealisationCard key={realisation.id} data={realisation} cardStyle="3" cardClassName="col-sm-6 col-lg-4 mb-4" />
              ))}
            </div>
          </div>
          : null}
        {data.lastRealisations.nodes.length ?
          <div className="border-top content-space-1 mx-lg-auto">
            <div className="mb-3 mb-sm-5">
              <h2>{t('lastRealisations')}</h2>
            </div>
            <div className="row">
              {data.lastRealisations.nodes.map(realisation => (
                <RealisationCard key={realisation.id} data={realisation} cardStyle="3" cardClassName="col-sm-6 col-lg-4 mb-4" />
              ))}
            </div>
          </div>
          : null}
      </div>
    </Layout>
  )
}

Realisation.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    realisation: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export default Realisation

export const query = graphql`
  query ($id: String!, $client: String, $partners: [String!], $slug: String, $language: String!) {
    site: site {
      siteMetadata {
        socialNetworks {
          linkedIn
          facebook
          messenger
          instagram
          twitter
        }
      }
    }
    realisation: mdx(
        fileAbsolutePath: { regex: "/data/realisations/"}
        id: {eq: $id}, 
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      ) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        title
        category
        tags
        technologies
        languages
        description
        client
        partners
        services
        image {
          cover {
            childImageSharp {
              gatsbyImageData(width: 1600)
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        galleries {
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        language
        path
        date(formatString: "MMMM YYYY", locale: $language)
      }
      body
      timeToRead
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        id: {eq: $id}, 
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___services) {
        title: fieldValue
        totalCount
      }
    }
    tags: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        id: {eq: $id}, 
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___tags) {
        title: fieldValue
        totalCount
      }
    }
    technologies: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        id: {eq: $id}, 
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___technologies) {
        title: fieldValue
        totalCount
      }
    }
    languages: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        id: {eq: $id}, 
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___languages) {
        title: fieldValue
        totalCount
      }
    }
    client: mdx(
      fileAbsolutePath: { regex: "/data/clients/"}
      frontmatter: { path: {eq: $client}, active: { eq: true }, language: {eq: $language} }
    ) {
      frontmatter {
        name
        description
        country
        website_url
        social_networks {
          google
          linkedIn
          facebook
          instagram
          twitter
        }
        image {
          logo {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          cover {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        home
        active
        language
        path
        date(formatString: "D MMMM YYYY")
      }
      id
      body
    }
    partners: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/partners/" }, 
        frontmatter: { path: { in: $partners }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___name, order: ASC}
    ) {
      nodes {
        frontmatter {
          name
          description
          home
          active
          language
          path
          date(formatString: "D MMMM YYYY")
        }
        id
        slug
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {realisations: { in: [$slug] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    relatedRealisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        id: {ne: $id},
        frontmatter: { client: {eq: $client}, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    lastRealisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        id: {ne: $id},
        frontmatter: { client: {ne: $client}, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "realisation", "client", "services", "countries", "categories", "tags", "technologies", "languages", "share-on", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`